<template>

</template>
<script>
var that
import Vue from "vue";
import { mapMutations } from 'vuex'

import http from '@/utils/httpRequest'
import {twoDomainLogin, getUrlParameter} from '@/utils/common'
import { Toast } from 'vant';
import successVue from '../activity/success.vue';
export default {
  data(){
    return{}
  },
  created(){
    this.isWxOrAli()
    that=this
    let token = that.$route.query.token
    console.log(that.$route.query)
    this.$cookies.set('token', token)
    Vue.prototype.$topBarShow = false
    let orgId = that.$route.query.orgId
    this.$cookies.set('orgId', orgId)
    Vue.prototype.$orgId = orgId
    let type=that.$route.query.type

    if(type) {
      sessionStorage.setItem('typeAdd', type)
    }
    // Vue.prototype.$type=that.$route.query.type
    Vue.prototype.$userCode = that.$route.query.userCode
    Vue.prototype.$userType = that.$route.query.userType // 居民自主登记 租客登记
    Vue.prototype.$subarea = that.$route.query.subarea || that.$route.query.subareaId
    Vue.prototype.$idNumber = that.$route.query.idNumber
    Vue.prototype.$subareaName = that.$route.query.subareaName
    Vue.prototype.$id = this.$route.query.id
    Vue.prototype.$userName = that.$route.query.userName
    Vue.prototype.$address = that.$route.query.address
    Vue.prototype.$health = this.$route.query.health
    let userId = that.$route.query.userId
    Vue.prototype.$globalData.userInfo.userId = userId
    Vue.prototype.$globalData.userInfo.assistId = that.$route.query.assistId
    Vue.prototype.$globalData.userInfo.streetName = that.$route.query.streetName
    Vue.prototype.$globalData.userInfo.communityName = that.$route.query.communityName
    Vue.prototype.$userId = userId
    Vue.prototype.$globalData.userInfo.houseId = that.$route.query.houseId
    Vue.prototype.$globalData.userInfo.orgId = orgId
    let code = that.$route.query.code
    Vue.prototype.$code = code
    Vue.prototype.$orgId = orgId
    if (that.$route.query.result) {
      Vue.prototype.$globalData.result = that.$route.query.result
    }
    Vue.prototype.$open = this.$route.query.open
    let curUrl = that.$route.query.curUrl
    Vue.prototype.$curUrl=curUrl
    let appid = that.$route.query.appid
    Vue.prototype.$appid=appid
    let appId = that.$route.query.appId
    Vue.prototype.$appId=appId
    let county = that.$route.query.county
    Vue.prototype.$globalData.userInfo.county = county
    let userName = that.$route.query.userName
    Vue.prototype.$globalData.userInfo.userName = userName
    let mobile = that.$route.query.mobile
    Vue.prototype.$globalData.userInfo.mobile = mobile
    let street = that.$route.query.street
    Vue.prototype.$globalData.userInfo.street = street
    Vue.prototype.$name = that.$route.query.name
    Vue.prototype.$communityId = that.$route.query.communityId
    Vue.prototype.$communityName = that.$route.query.communityName
    Vue.prototype.$buildingId = that.$route.query.buildingId
    Vue.prototype.$buildingName = that.$route.query.buildingName
    Vue.prototype.$qrcodeDepth = that.$route.query.qrcodeDepth
    Vue.prototype.$qrcodeId = that.$route.query.qrcodeId
    Vue.prototype.$unitId = that.$route.query.unitId
    Vue.prototype.$unitName = that.$route.query.unitName
    Vue.prototype.$houseName = that.$route.query.houseName

    Vue.prototype.$sex = that.$route.query.sex
    Vue.prototype.$age = that.$route.query.age
    Vue.prototype.$labelIds = that.$route.query.labelIds
    Vue.prototype.$isTenant = that.$route.query.isTenant
    Vue.prototype.$purePhoneNumber = that.$route.query.purePhoneNumber
    Vue.prototype.$qrcodeOptions = that.$route.query.options
    Vue.prototype.$unionid = that.$route.query.unionid
    Vue.prototype.$openid = that.$route.query.openid
    let domain = that.$route.query.domain
    if(that.$route.query.url === 'newRegister') {
      //兼容小程序登记码跳转到h5登记,获取domain以便切换
      if (domain && domain != '0' && domain != 'null' && domain !='undefined') {
        console.log(domain,'domain');
        Vue.prototype.$globalData.domain = decodeURIComponent(domain)
        Vue.prototype.$http.changeUrl(domain,true)
        Vue.prototype.$httpApp.changeUrl(domain)
        Vue.prototype.$httpCustApp.changeUrl(domain)
      }else {
        this.getDomainByOrgId(that.$route.query.orgId);
      }
    }else {
      // 二级域名处理

      if (domain && domain != '0' && domain != 'null' && domain !='undefined') {
        console.log(domain,'domain');
        Vue.prototype.$globalData.domain = decodeURIComponent(domain)
        Vue.prototype.$http.changeUrl(domain,true)
        Vue.prototype.$httpApp.changeUrl(domain)
        Vue.prototype.$httpCustApp.changeUrl(domain)
      }
    }

    // 微信h5二维码获取手机号
    if(this.$purePhoneNumber && this.$qrcodeOptions) {
      this.$http({
        url: this.$http.adornUrl('/wxapp/scanCode/getScanCodeParams'),
        method: 'get',
        params: this.$http.adornParams({
          id: this.$qrcodeOptions
        })
      }).then(({data}) => {
        if(data && data.code === 0) {
          Vue.prototype.$toSuccess = true
          if(data.data.depth == 4) {
            Vue.prototype.$globalData.userInfo.street = data.data.orgId
          }else {
            Vue.prototype.$orgId = data.data.orgId
          }
          Vue.prototype.$houseId = data.data.houseId
          Vue.prototype.$buildingId = data.data.buildingId
          Vue.prototype.$isTenant = data.data.isTenant
          Vue.prototype.$depth = data.data.depth
          Vue.prototype.$houseName = data.data.houseName
          this.$nextTick(() => {
            if( Vue.prototype.$curUrl=='safet2yCode'&&this.$openid && this.$unionid){
              this.$router.push('/saveCode' )
              return
            }
            if (this.$openid && this.$unionid&& Vue.prototype.$curUrl!='safet2yCode'){
              let appCode = this.$route.query.appCode
              this.$router.push({path: 'appSSO', query: {appCode,orgId:data.data.orgId}})
              return
            }else {
              this.$router.push('/' + data.data.src)
              return
            }
          })
        }
      })
  return
    }
    if (that.$route.query.mobile) {
      Vue.prototype.$mobile = that.$route.query.mobile
    }
    if (that.$route.query.idNumber) {
      Vue.prototype.$idNumber = that.$route.query.idNumber
    }
    Vue.prototype.$isOld = that.$route.query.isOld

    // 个别页面需要定位处理
    if (that.$route.query.longitude) {
      let location = {
        longitude: that.$route.query.longitude,
        latitude: that.$route.query.latitude
      }
      Vue.prototype.$location = location
    }

    if (that.$route.query.vIds) {
      console.log(that.$route.query.vIds)
      Vue.prototype.$globalData.volunteerIds = that.$route.query.vIds
      Vue.prototype.$curUrl='voluntSuccess'
    }

    // VueX传参方式进详情
    if (that.$route.query.casualTakingPhotos) {
      console.log(that.$route.query.casualTakingPhotos)
      this.$store.commit('setCasualTakingPhotos', that.$route.query.casualTakingPhotos)
    } else if (that.$route.query.name && that.$route.query.name == '提个醒') {
      this.$store.commit('setReminderData', {status: that.$route.query.status, id: that.$route.query.id, tabIndex: that.$route.query.tabIndex})
    } else if (that.$route.query.name && that.$route.query.name == '握一握') {
      this.$store.commit('setGripData', {status: that.$route.query.status, id: that.$route.query.id, tabIndex: that.$route.query.tabIndex})
    }


    // let url =window.location.href.split('sso')[0] + that.$route.query.url
    // window.location.replace(url)
    if (that.$route.query.url === 'handle-business') {
      this.$store.commit('setReturnData', that.$route.query)
    } else if (that.$route.query.url === 'activityCode' || that.$route.query.url === 'activitySuccess' || that.$route.query.url === 'questionWrite') {
      this.$router.push({path: that.$route.query.url, query:{ids: that.$route.query.ids}})
    } else if (that.$route.query.url === 'house' || that.$route.query.url === 'family' || that.$route.query.url === 'isolation') {
      let type
      if (that.$route.query.url === 'house') type = 1
      if (that.$route.query.url === 'family') type = 2
      if (that.$route.query.url === 'isolation') type = 3
      this.$router.push({path: 'house', query: {type}})
    } else if (that.$route.query.url === 'appSSO'  && (that.$route.query.appId !== 'integral' && that.$route.query.appId !== 'zyfw' && !that.$route.query.aIds)) {
      let url =  decodeURIComponent(that.$route.query.appUrl)
      var club
      var clubType
      var clubTitle
      var clubApplyWay
      var clubSignUpTime
      var clubisRegister
      var clubisRegister
      var organizationId
      if(that.$route.query.club){
        club=that.$route.query.club
      }
      if (that.$route.query.clubTitle) {
        clubTitle=that.$route.query.clubTitle
      }
      if (that.$route.query.clubType) {
        clubType=that.$route.query.clubType
      }
      if (that.$route.query.clubApplyWay) {
        clubApplyWay=that.$route.query.clubApplyWay
      }
      if (that.$route.query.clubSignUpTime) {
        clubSignUpTime=that.$route.query.clubSignUpTime
      }
      if (that.$route.query.clubisRegister) {
        clubisRegister=that.$route.query.clubisRegister
      }
      if(that.$route.query.organizationId){
        organizationId=that.$route.query.organizationId
      }
      let appId = that.$route.query.appId
      let appCode = that.$route.query.appCode
      let appName = that.$route.query.appName
      let id = that.$route.query.id
      let isOld = that.$route.query.isOld
      this.$router.push({path: that.$route.query.url, query: {url, appId, appCode, appName, club,organizationId,id,isOld,clubTitle,clubType,clubApplyWay,clubSignUpTime,clubisRegister,}})
      // this.$router.push({path: '/appSSO', query: {url: that.$route.query.curUrl}})
    } else if(that.$route.query.url === 'appSSO' && that.$route.query.curUrl === 'integral'){
      let url = decodeURIComponent(that.$route.query.curUrl)
      let appId = that.$route.query.appId
      let appCode = that.$route.query.appId
      let appName = that.$route.query.appName
      let id = that.$route.query.id
      let isOld = that.$route.query.isOld
      this.$router.push({path: that.$route.query.url, query: {url, appId, appCode, appName, id,isOld}})

    }else if(that.$route.query.url === 'appSSO' && that.$route.query.appId === 'zyfw'){
      let url = decodeURIComponent(this.$curUrl)
      let appId = that.$route.query.appId
      let appCode = that.$route.query.appId
      let appName = that.$route.query.appName
      let id = that.$route.query.id
      let isOld = that.$route.query.isOld
      this.$router.push({path: that.$route.query.url, query: {url, appId, appCode, appName, id,isOld}})
    }else if(that.$route.query.url === 'userRes-info-basic'){
      let activeName = that.$route.query.activeName
      this.$router.push({path: that.$route.query.url, query: {activeName}})
    } else if(that.$route.query.url === 'appSSO' && that.$route.query.aIds){
      Vue.prototype.$curUrl='activityInfo'
      let url = decodeURIComponent(process.env.VUE_APP_BASE_URL_H5 + '?url=' + this.$curUrl)
      let appId = '139'
      Vue.prototype.$appId = appId
      let appCode = 'activity'
      let id = that.$route.query.aIds
      let appName = null
      let isOld = null
      let way = that.$route.query.way
      this.$router.push({path: that.$route.query.url, query: {url, appId, appCode, appName, id,isOld, way}})
    }else {
      this.$router.push(that.$route.query.url)
    }
    if (that.$route.query.url === 'handle-business') {
      this.$store.commit('setReturnData', that.$route.query)
    }

        //监听微信小程序的返回按钮
    window.addEventListener('popstate', (event) => {
      //当不存在历史页面时，退出webview
      if (!event.state) {
        if (this.$agentType == 1) wx.miniProgram.navigateBack();
        if (this.$agentType == 2) my.navigateBack()
      }
    });
    // 微信扫码进积分通携带参数
    Vue.prototype.$placeId = that.$route.query.placeId
    Vue.prototype.$placeName = that.$route.query.placeName
    Vue.prototype.$placeOrgId = that.$route.query.placeOrgId
    // 微信扫h5二维码
    if(that.$route.query.params) {
      Vue.prototype.$appid = 'wxfa4ce77dc91e7327'
      this.getScanCodeParams()
    }
    this.isWeixinBrowser()
  },
  methods: {
    ...mapMutations,
    isWxOrAli() {
      var ua = window.navigator.userAgent.toLowerCase();
      //判断是不是微信
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        Vue.prototype.$agentType = 1
      }
      //判断是不是支付宝
      if (ua.match(/AlipayClient/i) == "alipayclient") {
        Vue.prototype.$agentType = 2
      }
    },
    isWeixinBrowser() {
      let ua = navigator.userAgent.toLowerCase();
	    return /micromessenger/.test(ua) ? true : false;
    },
    getScanCodeParams() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/scanCode/getScanCodeParams'),
        method: 'get',
        params: this.$http.adornParams({
          id: this.$route.query.params
        })
      }).then(({data}) => {
        if(data && data.code === 0) {
          if(window.location.href.indexOf('domain') != -1) {
            Vue.prototype.$toSuccess = true
          }
          if(data.data.depth == 4) {
            Vue.prototype.$globalData.userInfo.street = data.data.orgId
          }else {
            Vue.prototype.$orgId = data.data.orgId
          }
          Vue.prototype.$houseId = data.data.houseId
          Vue.prototype.$isTenant = data.data.isTenant
          Vue.prototype.$depth = data.data.depth
          Vue.prototype.$houseName = data.data.houseName
          this.$nextTick(() => {
            if(this.isWeixinBrowser()) {
              // 如果是微信内部浏览器就打开小程序
              const param = `params=${that.$route.query.params}`+ `&domain=${that.$route.query.domain}`
              this.$http({
                url: this.$http.adornUrl('/wxapp/getWxUrlScheme'),
                method: 'post',
                params: this.$http.adornParams({
                  param
                })
              }).then(({data}) => {
                if(data && data.code === 0) {
                  location.href = data.wxUrlScheme
                }
              })
            }else {
              this.$router.push('/' + data.data.src)
            }
          })
        }
      })
    },
    getDomainByOrgId(orgId){
      this.$http({
        url: this.$http.adornUrl('/wxapp/getJmggLibertyTwoDomainLogin'),
        method: 'post',
        params: this.$http.adornParams({
          orgId: orgId
        })
      }).then(({data}) => {
        if(data && data.code === 0) {
          Vue.prototype.$toSuccess = true
          if (data.domain){
            console.log('domain= ' + data.domain);
            Vue.prototype.$globalData.domain = decodeURIComponent(data.domain)
            Vue.prototype.$http.changeUrl(data.domain,true)
          }
        }
      })
    }
  }
}

</script>

<style scoped>

</style>
